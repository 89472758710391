<script setup>
import { $dayjs } from '@/utils/dayjs'

import { getImgSrc, getOpenGraph, getTwitter } from '~/helpers/useMetaData'
import { getPhotosFormatted } from '~/helpers/house/photos'
import { createSlugCacheHouseTransaction } from '~/helpers/house/createSlugCacheHouse'
import {
  formatRoomTags,
  formatRoomOutsideTags,
  formatEquipmentTags,
} from '~/helpers/house/sectionsTags.js'

defineI18nRoute({
  paths: {
    en: '/luxury-real-estate/[slug]',
    fr: '/immobilier-luxe/[slug]',
  },
})

definePageMeta({
  middleware: ['house-transaction'],
})

const nuxtApp = useNuxtApp()
const { $httpCookies } = nuxtApp
const { locale, t } = useI18n()
const { isSmallScreen } = useBreakpoint()
const route = useRoute()
const router = useRouter()
const localePath = useLocalePath()
const { setDates } = useHouseInquiryBooking()
const { setLock } = useLockBody()

$dayjs.locale(locale.value)

const { data: houseDataCached, error } = await useFetch(
  createSlugCacheHouseTransaction(route, locale.value),
)

if (error?.value) {
  showError({ statusCode: 404 })
}

const {
  houseAreas,
  houseBrands,
  houseDetails,
  houseEquipments,
  houseEquipmentTypes,
  housePools,
  housePhotos,
  houseSaleInformations,
} = houseDataCached.value

const newPhotos = (photos) => {
  const difference = photos
    .map((x) => x.id)
    .filter((x) => !housePhotos.photos.map((x) => x.id).includes(x))
  const newPhotos = photos.filter((x) => difference.includes(x.id))

  housePhotos.photos.push(...newPhotos)
}

const showInquiryModal = ref(false)

const getRoomTags = computed(() => {
  return formatRoomTags({
    $i18n: { t, locale },
    areas: houseAreas.filter((area) => area.inside),
    brands: houseBrands,
    equipments: houseEquipments,
    equipmentTypes: houseEquipmentTypes,
  })
})

const getEquipmentTags = computed(() => {
  return [
    ...formatRoomOutsideTags({
      $i18n: { t, locale },
      areas: houseAreas,
    }),
    ...formatEquipmentTags({
      $i18n: { t, locale },
      equipments: houseEquipments.filter((x) => !x.inside),
      equipmentTypes: houseEquipmentTypes,
    }),
  ]
})

const getPhotos = computed(() => getPhotosFormatted(housePhotos?.photos || []))

const hasDescription = computed(
  () => houseSaleInformations?.adDescription?.[locale.value]?.length,
)

const metaTitle = computed(() =>
  t('house.transaction.metaTitle', {
    property_name: houseDetails.name,
    destination: houseDetails.destination.name,
  }),
)

const metaDescription = computed(() => t('house.transaction.metaDescription'))

const openFullscreenPhoto = () => {
  const query = route.query['private-sale-token']
    ? { 'private-sale-token': route.query['private-sale-token'] }
    : null
  const housePhotosPath = localePath({
    name: 'house-transaction-photos',
    params: { slug: houseDetails.slug[locale.value] },
    query,
  })

  router.push(housePhotosPath)
}

const closeInquiry = () => {
  showInquiryModal.value = false
}

watch(
  () => showInquiryModal.value,
  (newVal) => {
    setLock(newVal)
  },
)

const dateCookies = $httpCookies?.get('search_params')

if (dateCookies?.from_date && dateCookies?.to_date) {
  const checkStartDate = $dayjs().isBefore(dateCookies?.from_date)
  const checkEndDate = $dayjs().isBefore(dateCookies?.to_date)

  if (!checkStartDate || !checkEndDate) {
    setDates(null, null)
    $httpCookies?.set('search_params', null)
  } else {
    setDates(dateCookies.from_date, dateCookies.to_date)
  }
} else {
  setDates(null, null)
  $httpCookies?.set('search_params', null)
}

nuxtApp.runWithContext(() => {
  useHead({
    title: metaTitle.value,
    meta: [
      {
        hid: 'description',
        name: 'description',
        content: metaDescription.value,
      },
      ...getOpenGraph(
        metaTitle.value,
        metaDescription.value,
        houseDetails.firstPhoto.url,
      ),
      ...getTwitter(
        metaTitle.value,
        metaDescription.value,
        houseDetails.firstPhoto.url,
      ),
    ],
    link: [...getImgSrc(houseDetails.firstPhoto.url)],
  })
})
</script>

<template>
  <div class="w-full">
    <HouseHeading
      :house="houseDetails"
      :get-photos="getPhotos"
      :total-photos="housePhotos.totalCount"
      @new-photos="newPhotos"
      @open-fullscreen="openFullscreenPhoto"
    />

    <template v-if="isSmallScreen">
      <HouseTransactionInquirySticky
        class="lg:hidden"
        :house-sale-informations="houseSaleInformations"
        @open="showInquiryModal = true"
      />

      <div
        :class="[
          'fixed left-0 top-0 z-modal w-full bg-white px-4 py-6',
          { invisible: !showInquiryModal },
        ]"
      >
        <div class="flex justify-end">
          <BaseCloseButton @click="closeInquiry" />
        </div>
        <HouseTransactionInquiry
          :house="houseDetails"
          :house-destinations="houseDetails.destination"
          :house-sale-informations="houseSaleInformations"
          :private-token="houseDetails.privateToken"
          :initial-step="1"
        />
      </div>
    </template>

    <div
      class="mx-auto mb-12 w-full max-w-full px-4 pt-6 sm:px-6 lg:max-w-container-xl"
    >
      <div class="lc-row justify-between">
        <div class="lc-col w-24/24 lg:w-13/24 xl:w-14/24">
          <HouseTransactionBreadcrumb
            v-if="$device.isDesktop"
            :house-name="houseDetails.name"
            class="mb-6"
          />
          <h1 class="mb-2 text-xl md:text-7xl">
            {{ $t('house.transaction.sale', { houseName: houseDetails.name }) }}
          </h1>
          <HouseDestinationBreadcrumb
            class="mb-8"
            :house-destinations="houseDetails.destination"
            witout-links
          />
          <HouseTransactionSummary
            :house="houseDetails"
            :house-rooms="houseSaleInformations.houseRooms"
          />

          <div v-if="hasDescription">
            <HouseDescription
              :button-collapse-text="{
                open: 'global.seeLess',
                close: 'house.transaction.readMore',
              }"
              :quote="houseSaleInformations.adDescription[$i18n.locale]"
              :show-icon="false"
              tracking-page="Transaction page"
            />
          </div>

          <HouseRooms
            :pools="housePools"
            :rooms-list="getRoomTags"
            class="mb-10 border-b border-gray-300 pb-10"
            tracking-page="Transaction page"
          />

          <HouseEquipments
            :equipment-tags="getEquipmentTags"
            :pools="housePools"
            :title="$t('house.transaction.amenities')"
            class="mb-10 border-b border-gray-300 pb-10"
            tracking-page="Transaction page"
          />

          <HouseSurrounding
            class="mb-10 border-b border-gray-300 pb-10"
            :get-gps="houseDetails.coordinates"
            :house="houseDetails"
            :house-destinations="houseDetails.destination"
          />

          <div class="mb-10 border-b border-gray-300 pb-10">
            <div class="bg-primary-100/50 p-6">
              <div class="font-monospace mb-3.5 text-5xl">
                {{ $t('house.transaction.ourRealEstateService') }}
              </div>

              <p class="mb-3.5">
                {{ $t('house.transaction.expertiseAndOurCollection') }}
              </p>

              <BaseButton color="secondary" :to="localePath('realEstate')">
                {{ $t('house.transaction.learnMoreAboutOurRealEstateService') }}
              </BaseButton>
            </div>
          </div>

          <HouseTransactionEnergyDiagnostics
            :house-destinations="houseDetails.destination"
            :house-sale-informations="houseSaleInformations"
            class="mb-10 border-b border-gray-300 pb-10"
          />

          <HouseTransactionFinancialInformation
            v-if="houseSaleInformations.feePayer"
            :currency="houseSaleInformations.currency"
            :fee-payer="houseSaleInformations.feePayer"
            :price="houseSaleInformations.price"
            :show-price="houseSaleInformations.displayPrices"
          />
        </div>

        <div class="lc-col relative hidden lg:block lg:w-10/24 xl:w-9/24">
          <div
            class="sticky top-20 z-40 mt-[-140px] border border-gray-300 bg-white"
          >
            <HouseTransactionInquiry
              :house="houseDetails"
              :house-destinations="houseDetails.destination"
              :house-sale-informations="houseSaleInformations"
              :private-token="houseDetails.privateToken"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
